<template>
  <div class="guide-cnt12">
    <h6 class="h6">退款说明</h6>
    <div class="cnt">
      <p class="p pm">1、申请退货退款：因微微定网站造成的质量问题订单，您可以在商品签收的30日内，申请退货退款处理；</p>
      <p class="p">2、退款方式：若因微微定的原因造成的退货时产生的款项，退回方式因支付方式的不同而不同：</p>
      <p class="p ps">1）网上银行支付的订单，退款退回至原支付卡；</p>
      <p class="p ps pm">2）由钱包支付的订单，退款退回至客户的钱包账户。</p>
      <p class="p">3、退款规则：</p>
      <p class="p ps">1）您的情况符合可退款条件且经微微定网站，该退款将返回您的微微定网站账户或退回原支付银行账户；</p>
      <p class="p ps">2）使用优惠券购买的商品只退还实际金额部分，优惠券不退还；</p>
      <p class="p ps pm">3）发生退货时，该所购商品所赠送的积分从您的积分账户里扣除。</p>
      <p class="p">4、商品质检周期及审核：</p>
      <p class="p ps">1）质检时间：微微定网站质检部会在收到您寄回商品后的2-3个工作日完成质检。</p>
      <p class="p ps">2）质检通过：如质检通过，您寄回的商品符合微微定网站办理退货退款标准，那么微微定网站会为您进行退货退款服务。</p>
      <p class="p ps pm">3）质检未通过：质检不符合退货退款标准的，我们将会和您联系确认后，把商品以到付的方式退还给您。</p>
      <p class="p">5、退款周期：自微微定网站质检部门确认后，7-15个工作日退回到您的账户（原支付卡或钱包账户，视支付方式及银行而定）。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt12',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt12 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
    }
    .ps {
      margin-bottom:10px;
    }
    .pm {
      margin-bottom:30px;
    }
  }
}
</style>
